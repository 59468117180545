import { MemberApi } from '@common/api/member';
import { classNames } from '@common/application/utils/classnames';
import { Cookie } from '@common/application/utils/cookie';
import { createPath } from '@common/application/utils/create-path';
import { Api } from '@common/application/utils/request';
// import LocalStorage from '@common/application/utils/storage';
import { Button } from '@common/components/common/button';
import { Checkbox } from '@common/components/common/checkbox';
import { Input } from '@common/components/common/input';
import AreaCodeSelect from '@common/components/common/select/area-code-select';
import { USER_STORAGE_KEY } from '@common/constants/common';
import env from '@common/constants/env';
import { generatePath } from '@common/constants/global-config';
import { ROUTE_MAP } from '@common/constants/route-map';
import { useFormik } from 'formik';
import Router from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import * as Yup from 'yup';

import { IconCircleInfo } from '@/components/icon/circle-info';

// import type { AreaCode } from '@common/types/checkout';

interface Props {
  onSuccess?: () => void;
  onNavigate?: () => void;
  styleType?: 'default' | 'modal';
}

export const LoginByPwd = (props: Props) => {
  const { onSuccess, onNavigate, styleType } = props;
  const { t } = useTranslation(['common']);
  const [headError, setHeadError] = useState('');
  const navigateToForget = () => {
    onNavigate?.();
    Router.push(createPath(ROUTE_MAP.RESET_PASSWORD));
  };
  // const recordAccount = (value: Object) => {
  //   LocalStorage.set(LOGIN_USERNAME, value);
  // };
  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      areaCode: { id: '2', code: '+852', name: '香港特别行政区' },
      phone: '',
      loginPassword: '',
      agreeToTerms: false
    },
    validationSchema: Yup.object().shape({
      phone: Yup.number().nullable().required(t('login.phone.emptyMsg')),
      loginPassword: Yup.string().trim().required(t('login.loginPassword.emptyMsg')),
      agreeToTerms: Yup.boolean().isTrue(t('login.agreeToTerms.emptyMsg'))
    }),
    onSubmit: async (values) => {
      if (headError) return;
      const params = {
        areaCode: values.areaCode.code,
        phone: values.phone,
        loginPassword: values.loginPassword
      };
      try {
        const { userSession } = await MemberApi.loginByPwd(params, { hideToast: true });
        Cookie.set(null, USER_STORAGE_KEY, userSession);
        setHeadError('');
        // if (values.agreeToTerms) recordAccount({ areaCode: values.areaCode, phone: values.phone });
        onSuccess?.();
      } catch (e: any) {
        if (e?.data?.errCode === 1) {
          setHeadError(t('login.errorTips.unregistered'));
        } else if (e?.data?.errCode === 2) {
          setHeadError(t('login.errorTips.passwordError'));
        } else {
          Api.errorHandle(e);
        }
      }
    }
  });
  const navigateToPolicy = () => {
    const href = generatePath(env.SERVICE_POLICY, t('common:footerLink.servicePolicy'));
    if (href) {
      Router.push(href);
    }
  };
  // useEffect(() => {
  //   const initPhoneValue = LocalStorage.get<{ phone: string; areaCode: AreaCode }>(
  //     LOGIN_USERNAME,
  //     undefined
  //   );
  //   if (initPhoneValue) {
  //     formik.setFieldValue('phone', initPhoneValue?.phone || '');
  //     formik.setFieldValue('areaCode', initPhoneValue?.areaCode || {});
  //   }
  // }, []);
  return (
    <div className="w-full">
      {styleType === 'modal' && <h2 className="text-xl font-medium mb-6">{t('login.login')}</h2>}
      <div className="grid">
        {headError ? (
          <div className={'w-full flex items-center text-sm text-red-form box-border pb-2'}>
            <IconCircleInfo className="!w-4 !h-4 mr-1" /> <span>{headError}</span>
          </div>
        ) : null}
        <div className="flex">
          <AreaCodeSelect
            className="w-19.5"
            selectClassName="border-r-0 rounded-r-none"
            name="areaCode"
            placeholder={t('login.firstName.placeholder')}
            value={formik.values.areaCode}
            onChange={(value) => formik.setFieldValue('areaCode', value)}
            loadFinish={(result) => {
              if (!formik.values.areaCode) {
                formik.setFieldValue('areaCode', result?.[0]);
              }
            }}
          />
          <Input.Number
            className={classNames('flex-1 pb-3 pc:pb-6', {
              'pb-0 pc:pb-0': formik.touched.phone && formik.errors.phone
            })}
            inputClassName="rounded-l-none"
            maxLength={8}
            placeholder={t('login.phone.placeholder')}
            {...formik.getFieldProps('phone')}
            onChange={(value) => {
              setHeadError('');
              formik.setFieldValue('phone', value);
            }}
            error={formik.touched.phone ? formik.errors.phone : ''}
          />
        </div>
        <Input.Password
          className={classNames('flex-1 pb-4', {
            'pb-0 pc:pb-0': formik.touched.loginPassword && formik.errors.loginPassword
          })}
          maxLength={16}
          placeholder={t('login.loginPassword.placeholder')}
          {...formik.getFieldProps('loginPassword')}
          onChange={(e) => {
            if (headError === t('login.errorTips.passwordError')) {
              setHeadError('');
            }
            formik.handleChange(e);
          }}
          error={formik.touched.loginPassword ? formik.errors.loginPassword : ''}
        />
        <div
          className={classNames('pb-6', {
            'pb-2': formik.touched.agreeToTerms && formik.errors.agreeToTerms
          })}
        >
          <Checkbox
            checked={formik.values.agreeToTerms}
            onChange={(value) => formik.setFieldValue('agreeToTerms', value)}
            iconClassName="!w-4 !h-4"
          >
            <div className="text-xs text-black-2">
              {t('login.agreeToTerms.agree')}
              <span className="text-primary cursor-pointer" onClick={navigateToPolicy}>
                {t('login.agreeToTerms.userAgreement')}
              </span>
              <span className="mx-0.5">{t('login.agreeToTerms.and')}</span>
              <span className="text-primary cursor-pointer" onClick={navigateToPolicy}>
                {t('login.agreeToTerms.privacyPolicy')}
              </span>
            </div>
          </Checkbox>
          {!!formik.touched.agreeToTerms && !!formik.errors.agreeToTerms && (
            <div className="text-red-form text-xs">{formik.errors.agreeToTerms}</div>
          )}
        </div>
        <div className="pb-6">
          <Button
            type="button"
            className={classNames('!flex w-full h-14 text-sm font-bold text-white !bg-primary', {
              'h-12 w-59 rounded-full mx-auto text-base': styleType === 'modal'
            })}
            onClick={() => formik.handleSubmit()}
          >
            {t('login.login')}
          </Button>
        </div>
      </div>
      <div className="text-center text-black-lightest">
        <span className="text-xs text-black-1 cursor-pointer" onClick={navigateToForget}>
          {t('login.forget')}
        </span>
      </div>
    </div>
  );
};

LoginByPwd.defaultProps = {
  onSuccess: () => null,
  styleType: 'default'
};
