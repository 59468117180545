import { useUpdateCartCount } from '@common/application/store/cart';
import { useUpdateUser } from '@common/application/store/user';
import { ServerSide } from '@common/application/utils/server-side';
import { ROUTE_MAP } from '@common/constants/route-map';
import type { GetServerSidePropsContext, NextPage } from 'next';
import Router from 'next/router';
import { useTranslation } from 'next-i18next';

import { LoginLayout } from '@/components/common/login-layout';
import { LoginTabs } from '@/components/common/login-tabs';
import { LoginByPwd } from '@/components/page/login/login-by-pwd';

const LoginPage: NextPage = () => {
  const { t } = useTranslation(['common']);
  const updateUser = useUpdateUser();
  const updateCartCount = useUpdateCartCount();

  const loginSuccess = () => {
    updateUser();
    updateCartCount();
    Router.push(ROUTE_MAP.HOME);
  };
  return (
    <LoginLayout title={t('login.login')}>
      <div className="w-full h-full grid place-items-center">
        <LoginTabs type="login">
          <LoginByPwd onSuccess={loginSuccess} />
        </LoginTabs>
      </div>
    </LoginLayout>
  );
};
export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  const i18nProps = await ServerSide.getTranslations(ctx, ['common']);
  return {
    props: {
      ...i18nProps
    }
  };
};

export default LoginPage;
