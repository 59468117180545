import { Footer } from '@/components/common/footer';
import { HeaderNotice } from '@/components/common/header/header-notice';
import { Logo } from '@/components/common/header/logo';

interface Props {
  title?: string;
  children: React.ReactNode;
}
export const LoginLayout = (props: Props) => {
  const { title, children } = props;
  return (
    <div className="w-full bg-white">
      <HeaderNotice showUser={false} />
      <div className="hidden container mx-auto h-21 pc:grid grid-cols-12 gap-3">
        <div className="col-span-2">
          <Logo type="primary" />
        </div>
        <div className="col-span-10 flex items-center text-black-1 text-2xl font-medium px-1">
          {title}
        </div>
      </div>
      <div className="pc:hidden h-16 grid place-items-center">
        <div className="w-41">
          <Logo type="primary" />
        </div>
      </div>
      <div className="flex-1 border-black-4 pb-15 pc:pt-15 pc:pb-20 pc:border-t">{children}</div>
      <Footer />
    </div>
  );
};
