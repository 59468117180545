import { createPath } from '@common/application/utils/create-path';
import { ROUTE_MAP } from '@common/constants/route-map';
/**
 * topic 文章页判断
 */
export const generatePath = (id, name) => {
    if (!id)
        return '';
    return createPath(ROUTE_MAP.TOPIC, { id, name });
};
export const GlobalConfig = () => {
    return {
        footerLink: [
            {
                title: 'common:footerLink.aboutUs',
                list: [
                    {
                        text: 'common:footerLink.aboutahaa',
                        href: ''
                    },
                    {
                        text: 'common:footerLink.memberPlan',
                        href: ''
                    },
                    {
                        text: 'common:footerLink.privacyPolicy',
                        href: ''
                    },
                    {
                        text: 'common:footerLink.terms',
                        href: ''
                    },
                    {
                        text: 'common:footerLink.notice',
                        href: ''
                    }
                ]
            },
            {
                title: 'common:footerLink.customerSupport',
                list: [
                    {
                        text: 'common:footerLink.pay',
                        href: ''
                    },
                    {
                        text: 'common:footerLink.FAQ',
                        href: ''
                    },
                    {
                        text: 'common:footerLink.send',
                        href: ''
                    },
                    {
                        text: 'common:footerLink.clean',
                        href: ''
                    },
                    {
                        text: 'common:footerLink.refund',
                        href: ''
                    }
                ]
            },
            {
                title: 'common:footerLink.connectUs',
                list: [
                    {
                        text: 'common:footerLink.connectahaa',
                        href: ''
                    },
                    {
                        text: 'common:footerLink.address',
                        href: ''
                    },
                    {
                        text: 'common:footerLink.gift',
                        href: ''
                    }
                ]
            }
        ],
        footerPayLogo: [
            {
                icon: 'visa',
                href: ''
            },
            {
                icon: 'mostercard',
                href: ''
            },
            {
                icon: 'wechatpay',
                href: ''
            },
            {
                icon: 'payme',
                href: ''
            },
            {
                icon: 'alipay',
                href: ''
            },
            {
                icon: 'unionpay',
                href: ''
            },
            {
                icon: 'octpay',
                href: ''
            },
            {
                icon: 'ame',
                href: ''
            }
        ]
    };
};
