import { jsx as _jsx } from "react/jsx-runtime";
/*
 * @Author: 朱璐
 * @Date: 2022-11-08 18:38:24
 * @Description: 区号选择
 */
import { CommonApi } from '@common/api/common';
import { classNames } from '@common/application/utils/classnames';
import { useEffect, useState } from 'react';
import Select from '.';
const AreaCodeSelect = (props) => {
    const [areaCode, setAreaCode] = useState([]);
    useEffect(() => {
        CommonApi.getAreaCode().then((res) => {
            setAreaCode(res);
            if (props.loadFinish) {
                props.loadFinish(res);
            }
        });
    }, []);
    return (_jsx(Select, { disabled: props.disabled, labelKey: "code", className: classNames('w-24', props.className), selectClassName: props.selectClassName, name: "areaCode", options: areaCode, placeholder: props.placeholder, label: props.label, value: props.value, onChange: props.onChange, error: props.error }));
};
export default AreaCodeSelect;
