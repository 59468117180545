import { classNames } from '@common/application/utils/classnames';
import { ROUTE_MAP } from '@common/constants/route-map';
import Router from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

type Type = 'login' | 'register';

interface TabItem {
  id: Type;
  title: string;
  path: string;
}

interface Props {
  className?: string;
  type: Type;
  children: React.ReactNode;
  onTabChange?: (id: Type) => void;
}

export const LoginTabs = (props: Props) => {
  const { className, type, children, onTabChange } = props;
  const { t } = useTranslation('common');
  const [active, setActive] = useState<Type>(type);

  const LOGIN_TABS_LIST: TabItem[] = [
    {
      id: 'login',
      title: t('login.login'),
      path: ROUTE_MAP.LOGIN
    }
  ];

  const handleTabChange = (item: TabItem) => {
    if (onTabChange) {
      setActive(item.id);
      onTabChange(item.id);
    } else {
      Router.push(item.path);
    }
  };

  useEffect(() => {
    setActive(type);
  }, [type]);

  return (
    <div
      className={classNames('w-full pt-4 px-4', 'pc:w-98 pc:pt-2.25 pc:pb-11 pc:px-6', className)}
    >
      <div className="flex justify-center">
        {LOGIN_TABS_LIST.map((item) => (
          <div
            key={item.title}
            className={classNames(
              'relative grid place-items-center text-black-3 cursor-pointer',
              'w-20 h-11.5 text-base',
              'pc:w-28 pc:h-13 pc:text-xl',
              { 'text-primary font-semibold': active === item.id }
            )}
            onClick={() => handleTabChange(item)}
          >
            {item.title}
            {active === item.id && (
              <div
                className={classNames('absolute bottom-0 h-0.5 bg-primary', 'w-4', 'pc:w-8')}
              ></div>
            )}
          </div>
        ))}
      </div>
      <div className="w-full pt-4 pc:pt-4.25">{children}</div>
    </div>
  );
};
